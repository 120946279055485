.market-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
}

.market-header {
  margin-bottom: 24px;
}

.market-header h1 {
  font-size: 28px;
  margin-bottom: 8px;
  color: #333;
}

.market-header p {
  color: #666;
  font-size: 16px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 8px;
}

.table-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.table-actions {
  display: flex;
  gap: 24px;
  align-items: center;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stat-label {
  color: #666;
  font-size: 14px;
}

.stat-value {
  font-weight: 500;
  font-size: 16px;
}

.stat-value.success {
  color: #28a745;
}

.detail-header {
  margin-bottom: 24px;
}

/* 科技风格表格 */
.ant-table {
  background: #fbfbff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(49, 92, 236, 0.1);
}

.ant-table-thead > tr > th {
  background: #315cec;
  color: white;
  font-weight: 500;
}

.ant-table-tbody > tr:hover > td {
  background: rgba(49, 92, 236, 0.05);
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e7e7ea;
}

.ant-pagination-item-active {
  border-color: #315cec;
}

.ant-pagination-item-active a {
  color: #315cec;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .market-container {
    margin: 20px auto;
  }
  
  .table-actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
} 