.app-header {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(49, 92, 236, 0.1);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.app-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.app-header .logo {
  font-size: 24px;
  font-weight: bold;
  color: #315cec;
  display: flex;
  align-items: center;
}

.app-header .logo a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.logo-chinese {
  font-size: 0.8em;
  margin-left: 4px;
  opacity: 0.8;
}

.app-header .main-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-header .main-nav li {
  margin-left: 30px;
}

.app-header .main-nav a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s;
}

.app-header .main-nav a:hover,
.app-header .main-nav a.active {
  color: #315cec;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-buttons .btn {
  min-width: 80px;
  text-align: center;
}

.btn {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  font-size: 14px;
  text-decoration: none;
}

.btn-primary {
  background: #315cec;
  color: white;
}

.btn-primary:hover {
  background: #2a4fd0;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(49, 92, 236, 0.2);
}

.btn-outline {
  background: transparent;
  border: 1px solid #315cec;
  color: #315cec;
}

.btn-outline:hover {
  background-color: rgba(49, 92, 236, 0.1);
  transform: translateY(-2px);
}

.network-badge {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.test-network-label {
  background-color: var(--warning-color, #ffc107);
  color: var(--text-primary, #333);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.tooltip-container {
  position: relative;
  margin-left: 5px;
}

.info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--border-color, #e7e7ea);
  color: var(--text-light, #999);
  font-size: 12px;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  padding: 8px 12px;
  background-color: var(--text-primary, #333);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: var(--shadow-medium, 0 4px 8px rgba(0, 0, 0, 0.1));
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 5px solid transparent;
  border-bottom-color: var(--text-primary, #333);
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.logo-container {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .app-header .container {
    flex-wrap: wrap;
  }
  
  .app-header .main-nav {
    order: 3;
    width: 100%;
    margin-top: 15px;
  }
  
  .app-header .main-nav ul {
    justify-content: center;
  }
  
  .app-header .main-nav li {
    margin: 0 15px;
  }
  
  .auth-buttons {
    margin-left: auto;
  }
  
  .app-header .logo {
    display: flex;
    align-items: center;
  }
  
  .network-badge {
    margin-left: 10px;
  }
}

@media (max-width: 576px) {
  .app-header .container {
    flex-direction: column;
  }
  
  .app-header .logo {
    margin-bottom: 10px;
  }
  
  .auth-buttons {
    margin: 15px 0;
    width: 100%;
    justify-content: center;
  }
  
  .btn {
    padding: 8px 12px;
  }
  
  .network-badge {
    margin-left: 8px;
  }
  
  .test-network-label {
    font-size: 10px;
    padding: 3px 6px;
  }
} 