:root {
  --primary-color: #315cec;
  --primary-light: #4a6eee;
  --primary-dark: #2a4fd0;
  --bg-color: #fbfbff;
  --surface-color: #ffffff;
  --border-color: #e7e7ea;
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #999999;
  --success-color: #28a745;
  --error-color: #dc3545;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --shadow-small: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-large: 0 8px 16px rgba(0, 0, 0, 0.15);
  --border-radius-small: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 12px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;
  --transition: all 0.3s ease;
  --white: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: var(--text-primary);
  background-color: var(--bg-color);
  line-height: 1.6;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* 按钮样式 */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: var(--border-radius-medium);
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: var(--transition);
  border: none;
  font-size: 16px;
}

.btn-primary {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: var(--white);
}

.btn-primary:hover {
  background: linear-gradient(135deg, var(--primary-dark), var(--primary-color));
  transform: translateY(-2px);
  box-shadow: var(--shadow-medium);
}

.btn-outline {
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-outline:hover {
  background-color: var(--primary-color);
  color: var(--white);
  transform: translateY(-2px);
}

.btn-large {
  padding: 12px 24px;
  font-size: 16px;
}

/* 头部样式 */
.site-header {
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.site-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.site-logo {
  font-size: 28px;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo-chinese {
  font-size: 20px;
  margin-left: 5px;
  color: var(--primary-dark);
}

.network-badge {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.test-network-label {
  background-color: var(--warning-color);
  color: var(--text-primary);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.tooltip-container {
  position: relative;
  margin-left: 5px;
}

.info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--border-color);
  color: var(--text-light);
  font-size: 12px;
  font-weight: bold;
  cursor: help;
}

.tooltip-container:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  padding: 8px 12px;
  background-color: var(--text-primary);
  color: var(--white);
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: var(--shadow-medium);
}

.tooltip:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 5px solid transparent;
  border-bottom-color: var(--text-primary);
}

/* 导航样式 */
.main-nav ul {
  display: flex;
  list-style: none;
}

.main-nav li {
  margin: 0 15px;
}

.main-nav a {
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 500;
  transition: var(--transition);
  padding: 5px 0;
  position: relative;
}

.main-nav a:hover,
.main-nav a.active {
  color: var(--primary-color);
}

.main-nav a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: var(--transition);
}

.main-nav a:hover:after,
.main-nav a.active:after {
  width: 100%;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

/* 英雄区域 */
.hero {
  position: relative;
  padding: 100px 0;
  overflow: hidden;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(49, 92, 236, 0.05) 0%, rgba(49, 92, 236, 0) 70%);
  z-index: -1;
}

.hero .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--text-primary);
  line-height: 1.2;
}

.highlight {
  color: var(--primary-color);
  position: relative;
}

.highlight:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: var(--primary-light);
  opacity: 0.3;
  z-index: -1;
}

.hero-subtitle {
  font-size: 20px;
  color: var(--text-secondary);
  margin-bottom: 30px;
}

.hero-buttons {
  display: flex;
  gap: 15px;
}

.hero-image {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 100%;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-large);
}

.floating-data {
  position: absolute;
  background-color: var(--surface-color);
  border-radius: var(--border-radius-small);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: var(--shadow-medium);
}

.floating-data-1 {
  top: 20%;
  left: -10%;
}

.floating-data-2 {
  bottom: 20%;
  right: -10%;
}

.data-icon {
  font-size: 24px;
}

.data-title {
  font-size: 12px;
  color: var(--text-light);
}

.data-value {
  font-size: 14px;
  font-weight: 600;
}

.data-value.up {
  color: var(--success-color);
}

/* AI 等级部分 */
.ai-levels {
  padding: 80px 0;
  background-color: var(--surface-color);
}

.section-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
  color: var(--text-primary);
}

.levels-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.level-card {
  background-color: var(--bg-color);
  border-radius: var(--border-radius-medium);
  padding: 30px;
  transition: var(--transition);
  box-shadow: var(--shadow-small);
}

.level-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-medium);
}

.level-card.featured {
  background-color: var(--primary-color);
  color: var(--white);
  transform: scale(1.05);
  box-shadow: var(--shadow-medium);
}

.level-card.featured:hover {
  transform: scale(1.05) translateY(-10px);
  box-shadow: var(--shadow-large);
}

.level-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.level-badge {
  background-color: var(--primary-light);
  color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-right: 15px;
}

.level-card.featured .level-badge {
  background-color: var(--white);
  color: var(--primary-color);
}

.level-title {
  font-size: 24px;
  font-weight: 600;
}

.level-content {
  margin-bottom: 30px;
}

.level-content p {
  margin-bottom: 20px;
}

.level-features {
  list-style-type: none;
}

.level-features li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.level-features li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--primary-color);
  font-weight: 700;
}

.level-card.featured .level-features li:before {
  color: var(--white);
}

.level-footer {
  text-align: center;
}

/* 比较表格部分 */
.comparison {
  padding: 80px 0;
  background-color: #fbfbff;
  position: relative;
}

.comparison-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(49, 92, 236, 0.1);
  overflow: hidden;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
}

.comparison-table th {
  background-color: #315cec;
  color: white;
  padding: 15px;
  text-align: left;
}

.comparison-table td {
  padding: 15px;
  border-bottom: 1px solid #e7e7ea;
}

.comparison-table tr:last-child td {
  border-bottom: none;
}

.comparison-table tr:hover td {
  background-color: rgba(49, 92, 236, 0.05);
}

/* CTA 部分 */
.cta {
  padding: 100px 0;
  background: linear-gradient(135deg, #315cec, #4a6eee);
  color: white;
  position: relative;
  overflow: hidden;
}

.cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/circuit-pattern.svg') center/cover no-repeat;
  opacity: 0.1;
  z-index: 0;
}

.cta-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.cta h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.cta p {
  font-size: 18px;
  margin-bottom: 30px;
  opacity: 0.9;
}

.cta .btn-primary {
  background: white;
  color: #315cec;
}

.cta .btn-primary:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* 页脚部分 */
.site-footer {
  background-color: #1a2942;
  color: var(--white);
  padding: 60px 0 30px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.footer-title {
  font-size: 18px;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.footer-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: var(--primary-light);
}

.footer-about p {
  opacity: 0.8;
  line-height: 1.8;
}

.footer-links ul,
.footer-contact ul {
  list-style: none;
}

.footer-links li,
.footer-contact li {
  margin-bottom: 10px;
}

.footer-links a {
  color: var(--white);
  opacity: 0.8;
  text-decoration: none;
  transition: var(--transition);
}

.footer-links a:hover {
  opacity: 1;
  color: var(--primary-light);
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  display: inline-block;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: var(--white);
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-icon:hover {
  background-color: var(--primary-light);
  color: white;
  transform: translateY(-2px);
}

.footer-bottom {
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0.6;
  font-size: 14px;
}

/* 响应式设计 */
@media (max-width: 992px) {
  .hero .container {
    flex-direction: column;
    text-align: center;
  }
  
  .hero-content {
    margin-bottom: 40px;
  }
  
  .hero-buttons {
    justify-content: center;
  }
  
  .floating-data-1 {
    top: 10%;
    left: 10%;
  }
  
  .floating-data-2 {
    bottom: 10%;
    right: 10%;
  }
  
  .level-card.featured {
    transform: scale(1);
  }
  
  .level-card.featured:hover {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .site-header .container {
    flex-wrap: wrap;
  }
  
  .main-nav {
    order: 3;
    width: 100%;
    margin-top: 15px;
  }
  
  .main-nav ul {
    justify-content: center;
  }
  
  .hero-title {
    font-size: 36px;
  }
  
  .hero-subtitle {
    font-size: 18px;
  }
  
  .section-title {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .main-nav li {
    margin: 0 10px;
  }
  
  .hero-title {
    font-size: 30px;
  }
  
  .floating-data {
    display: none;
  }
  
  .levels-container {
    gap: 20px;
  }
  
  .level-card {
    padding: 20px;
  }
}

/* 添加以下样式 */

.tech-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.features {
  padding: 80px 0;
  background-color: #fbfbff;
  position: relative;
}

.features::before {
  content: '';
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, transparent, #fbfbff);
  z-index: 1;
}

.data-section {
  padding: 60px 0;
  background-color: #fff;
}

/* 添加 AI 打字效果的样式 */
.ai-typing-section {
  padding: 40px 0;
  background-color: #fff;
  position: relative;
}

.ai-typing-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgba(49, 92, 236, 0.2), transparent);
}

.ai-typing-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgba(49, 92, 236, 0.2), transparent);
} 